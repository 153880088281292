<script>
import { Line } from 'vue-chartjs'
import repository from "../repositories/StatisticsRepository";

export default {
  name: 'StatisticsChart',
  extends: Line,
  props: [
      'chartTitle',
      'chartKey',
  ],
  watch: {
      chartTitle: function() {
            this.RevChartData.datasets[0].data = null
            this.RevChartData.datasets[1].data = null
            this.RevChartOptions.title.text = this.chartTitle
            if (this.chartKey != null)
                this.getAllDataAndRender(this.RevChartData.datasets[0].label, this.RevChartData.datasets[1].label)
            this.renderChart(this.RevChartData, this.RevChartOptions)
      }
  },
  data() {
    return {
        RevChartData: {
            labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            datasets: [{
                type: "line",
                label: null,
                data: null,
                backgroundColor: "rgba(71, 177, 125, .4)",
                borderColor: "rgba(33, 153, 100, .9)",
                borderWidth: 2,
                order: 1
            }, {
                type: "line",
                label: null,
                data: null,
                backgroundColor: "rgba(240, 205, 102, .4)",
                borderColor: "rgba(233, 184, 35, .9)",
                borderWidth: 1,
                order: 2
            }]
        },
        RevChartOptions: {
            title: {
                display: true,
                text: this.chartTitle,
                position: 'top',
                fontSize: 34,
                fontColor: '#e2420d',
            },
            maintainAspectRatio: false,
            responsive: true,
            lineTension: 1,
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        padding: 10,
                    }
                }]
            },
        },
    }
  },
  methods: {
    setChartYears() {
        const date = new Date().getFullYear();
        this.RevChartData.datasets[0].label = date.toString();
        this.RevChartData.datasets[1].label = (date - 1).toString();
    },
    monthDictToList(dict) {
        var newList = []
        var i
        for (i = 0; i < dict.length; i++) {
            newList.push(dict[i].amount)
        }
        return newList
    },
    async getAllDataAndRender(currentYear, lastYear) {
        const responseCurrent = await repository.getMonthlyStatsByYear(currentYear, this.chartKey)
        // console.log(responseCurrent)
        const responseLast = await repository.getMonthlyStatsByYear(lastYear, this.chartKey)
        const totalUnpackKeyword = ("total_" + this.chartKey).replace('-', '_')
        this.RevChartData.datasets[0].data = this.monthDictToList(responseCurrent.data[totalUnpackKeyword])
        this.RevChartData.datasets[1].data = this.monthDictToList(responseLast.data[totalUnpackKeyword])
        this.renderChart(this.RevChartData, this.RevChartOptions)
    },
  },
  mounted() {
    this.setChartYears()
    if (this.chartKey != null)
        this.getAllDataAndRender(this.RevChartData.datasets[0].label, this.RevChartData.datasets[1].label)
    this.renderChart(this.RevChartData, this.RevChartOptions)
  }
}
</script>

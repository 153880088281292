import axios from "axios";
import errorHandler from "./Repository";

const resource = "/dashboard/statistics";

export default {
  getAllStats(startDate = null, endDate = null) {
    return axios
      .get(`${resource}?start_date=${startDate}&end_date=${endDate}`)
      .then(({ data }) => {
        return data;
      })
      .catch(errorHandler);
  },
  getMonthlyStatsByYear(year = null, field = null) {
    return axios
      .get(`${resource}/${field}?year=${year}`)
      .then(({ data }) => {
        return data;
      })
      .catch(errorHandler);
  },
};

<template>
  <div>
    <b-row>
      <b-col cols="12" md="6">
        <h2 class="my-4 font-weight-bolder">LULA Statistics</h2>
      </b-col>
      <b-col cols="12" md="6" class="month-selector">
        <v-select
          v-model="month"
          :options="months"
          id="value"
          label="name"
          @input="getStats"
        />
        <p>Displaying for the month of:</p>
      </b-col>
      <b-col cols="12">
        <hr />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <StatsChart :chartTitle="chartTitle" :chartKey="chartKey" />
      </b-col>
    </b-row>
    <b-row v-for="link in links" :key="link.name">
      <b-col v-if="link.name" cols="12">
        <h2 class="my-4 font-weight-bolder">{{ link.name }}</h2>
        <hr />
      </b-col>
      <b-col cols="6" md="3" v-for="menu in link.menu" :key="menu.name">
        <a :href="menu.link" target="_blank">
          <div class="card my-2 shadow hover-blue" @click="toggleShowChart(menu)">
            <div class="card-body p-2">
              <b-row class="d-flex flex-wrap align-items-center">
                <b-col sm="4">
                  <b-img :src="menu.img" fluid></b-img>
                </b-col>
                <b-col sm="8">
                  <b-row>
                    <b-col sm="12">{{ menu.name }}</b-col>
                    <b-col sm="12">
                      <h4>{{ menu.value }}</h4>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </div>
        </a>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue'
import VueSocketIO from 'vue-socket.io'
import repository from "../repositories/StatisticsRepository";
import moment from 'moment'
import VueMomentJS from "vue-momentjs";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import StatsChart from '../components/StatsChart.vue'

Vue.component('v-select', vSelect)
Vue.use(VueMomentJS, moment);

const options = { path: '/socket.io' }; // Options object to pass into SocketIO

Vue.use(new VueSocketIO({
    debug: true,
    connection: 'https://socket.lulaloop.co.za/',
    options: options,
  })
);

export default {
  name: "Home",
  components: {
    StatsChart
  },
  data: () => ({
    chartTitle: 'Please select a chart from the cards below',
    chartKey: null,
    month: null,
    monthInt: null,
    months: [],
    links: [
      {
        color: "hover-blue",
        menu: [
          {
            name: "Revenue",
            apiKey: "revenue",
            desc: "",
            value: '0',
            img: require("@/assets/lula-red.png")
          },
          {
            name: "Users",
            apiKey: "users",
            desc: "",
            value: '0',
            img: require("@/assets/lula-red.png")
          },
          {
            name: "Deliveries",
            apiKey: "delivery",
            desc: "",
            value: '0',
            img: require("@/assets/lula-red.png")
          },
          {
            name: "LULA Business",
            desc: "",
            value: '0',
            img: require("@/assets/lula-red.png")
          },
          {
            name: "LULA Commuter",
            desc: "",
            value: '0',
            img: require("@/assets/lula-red.png")
          },
          {
            name: "Driver App Downloads",
            desc: "",
            value: '0',
            img: require("@/assets/lula-red.png")
          },
          {
            name: "Commuter App Downloads",
            desc: "",
            value: '0',
            img: require("@/assets/lula-red.png")
          },
          {
            name: "Commuters",
            desc: "",
            value: '0',
            img: require("@/assets/lula-red.png")
          },
          {
            name: "Operators",
            apiKey: "operator-accounts",
            desc: "",
            value: '0',
            img: require("@/assets/lula-red.png")
          },
          {
            name: "Vetted Drivers",
            apiKey: "driver-accounts",
            desc: "",
            value: '0',
            img: require("@/assets/lula-red.png")
          },
          {
            name: "Driver Expected KM",
            desc: "",
            value: '0',
            img: require("@/assets/lula-red.png")
          },
          {
            name: "Driver Actual Trips",
            apiKey:"trips",
            value: '0',
            img: require("@/assets/lula-red.png")
          },
          {
            name: "Driver Payments",
            desc: "",
            value: '0',
            img: require("@/assets/lula-red.png")
          },
        ]
      },
      {
        name: "Social Media",
        color: "hover-blue",
        menu: [
          {
            name: "Twitter ",
            desc: "",
            value: '0',
            img: require("@/assets/twitter.png")
          },
          {
            name: "Instagram ",
            desc: "",
            value: '0',
            img: require("@/assets/instagram.png")
          },
          {
            name: "LinkedIn ",
            desc: "",
            value: '0',
            img: require("@/assets/linkedin.png")
          },
          {
            name: "Facebook ",
            desc: "",
            value: '0',
            img: require("@/assets/facebook.png")
          }
        ]
      }
    ]
  }),
  methods: {
    toggleShowChart(menu) {
      this.chartKey = menu.apiKey
      this.chartTitle = menu.name
    },
    numberFormat(num) {
      if (num > 999 && num < 1000000) {
        return (num/1000).toFixed(0) + 'K'; // convert to K for number from > 1000 < 1 million
      } else if (num > 1000000) {
        return (num/1000000).toFixed(0) + 'M'; // convert to M for number from > 1 million
      } else if (num < 900) {
        return num; // if value < 1000, nothing to do
      }
    },
    buildMonthsSelect () {
      for (var i = 1; i <= 12; i++) {
        var monthString = i < 10 ? '0' + i : i
        var date = this.$moment().format('YYYY') + '-' + monthString + '-' + this.$moment().format('DD')
        this.months.push({
            name: this.$moment(date).format('MMMM'),
            value: i
        })
      }
    },
    updateStatCard (cardName, value) {
      const filtered = this.links[0].menu.filter(item => item.name.toLowerCase() === cardName.toLowerCase())
      if (filtered.length > 0) {
        filtered[0].value = value
      }
    },
    async getStats (something) {
      if (something) {
        this.month = something.name
        this.monthInt = something.value
      }
      const currentYear = this.$moment().format('YYYY')
      const monthString = this.monthInt < 10 ? '0' + this.monthInt : this.monthInt
      const startDate = currentYear + '-' + monthString + '-01'
      var endDay = this.$moment(startDate).endOf('month').format('DD')
      const endDate = currentYear + '-' + monthString + '-' + endDay
      const response = await repository.getAllStats(startDate, endDate)
      const responseData = response.data
      if (typeof responseData.total_users !== 'undefined') {
          this.updateStatCard('users', responseData.total_users)
      }

      if (typeof responseData.total_commuter_accounts !== 'undefined') {
          this.updateStatCard('commuters', responseData.total_commuter_accounts)
      }

      if (typeof responseData.total_operator_accounts !== 'undefined') {
        this.updateStatCard('operators', responseData.total_operator_accounts)
      }

      if (typeof responseData.total_driver_accounts !== 'undefined') {
        this.updateStatCard('vetted drivers', responseData.total_driver_accounts)
      }

      if (typeof responseData.total_delivery_accounts !== 'undefined') {
        this.updateStatCard('delivery account', responseData.total_delivery_accounts)
      }

      if (typeof responseData.total_organization_accounts !== 'undefined') {
        this.updateStatCard('organizations', responseData.total_organization_accounts)
      }

      if (typeof responseData.total_deliveries !== 'undefined') {
        this.updateStatCard('deliveries', responseData.total_deliveries)
      }

      if (typeof responseData.total_revenue !== 'undefined') {
          this.updateStatCard('revenue', this.numberFormat(responseData.total_revenue))
      }
    }
  },
  mounted () {
    this.buildMonthsSelect()
    this.month = this.$moment().format('MMMM')
    this.monthInt = this.$moment().format('MM')
    this.getStats()
    this.$socket.emit('join_room', {room: 'live_stats'})
    this.sockets.subscribe('room_live_stats_new_message', function(data) {
      if (data.data && data.room && data.room === 'live_stats') {
        data = data.data.message
        switch (data.id) {
          case 'commuter_account_update':
            this.updateStatCard('commuters', data.data)
            break
          case 'operator_account_update':
            this.updateStatCard('operators', data.data)
            break
          case 'driver_account_update':
            this.updateStatCard('drivers', data.data)
            break
          case 'organization_account_update':
            this.updateStatCard('organizations', data.data)
            break
          case 'delivery_account_update':
            this.updateStatCard('delivery account', data.data)
            break
          case 'delivery_update':
            this.updateStatCard('deliveries', data.data)
            break;
        }
      }
    })
  },
};
</script>

<style scoped>
.card,
.card-header {
  border-radius: 20px !important;
  border: none !important;
}
.card-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.hover-red:hover {
  box-shadow: 0 0.5rem 1rem #e2420d !important;
}
.hover-blue:hover {
  box-shadow: 0 0.4rem 1rem #259de8 !important;
}
.hover-green:hover {
  box-shadow: 0 0.5rem 1rem #3cc380 !important;
}
.hover-yellow:hover {
  box-shadow: 0 0.5rem 1rem #f0cd66 !important;
}
a:hover {
  text-decoration: none !important;
}
.month-selector {
  align-items: center;
  display: flex;
  flex-flow: row-reverse;
}
.month-selector p {
  margin-bottom: 0;
  margin-right: 10px;
}

.v-select {
  min-width: 40% !important;
}

.card:hover {
  cursor: pointer;
}
</style>
